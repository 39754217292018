<template>
    <div class="task-comment-form-container comments_desk_omain_x onlyborderspace">
        <p class="fw-title mb-2 d-flex justify-content-between align-content-center" v-if="taskCommentsData.length > 0">
            <i>
                <span>                                        
                    <CommentTaskPreSvgIcon />
                </span>
                <b>All Comments</b>
            </i>
        </p> 
        <p class="fw-title mb-2 d-flex justify-content-between align-content-center" v-else>
            <i>
                <span>                                        
                    <CommentTaskPreSvgIcon />
                </span>
                <b>All Comments</b>
            </i>
        </p> 
        <div class="row">
            <!-- <ComponentLoader v-if="comLoader == 1"/> -->

            <div class="d-flex mb-2 rowtaskmnc shimmer-wrapper" v-if="comLoader == 1" >
                <div class="flex-shrink-0 comment-avatar">
                    <div class="mg-thumbnail shimmer-line-wd shimmer-animate"></div>
                </div>
                <div class="w-100 ps-2">
                    <div class="mb5 mb-2 d-flex align-items-center justify-content-between">
                        <span class="dark strong shimmer-line shimmer-line-br shimmer-line-w30 shimmer-animate"></span>
                        <span class="text-off shimmer-line shimmer-line-br shimmer-line-w20 shimmer-animate"></span>
                    </div>
                   <div class="despOP_despton">
                        <p class="shimmer-line-full shimmer-animate shimmer-line shimmer-line-br mb-1"></p>
                        <p class="shimmer-line-w60 shimmer-animate shimmer-line shimmer-line-br mb-1"></p>
                        <p class="shimmer-line-w40 shimmer-animate shimmer-line shimmer-line-br"></p>
                   </div> 
                </div>
            </div>

            <div class="d-flex mb-2 rowtaskmnc " v-for="(itemCmt) in taskCommentsData" :key="itemCmt._id" v-else>
                <div class="flex-shrink-0 comment-avatar">
                    <div class="mg-thumbnail">
                        <img v-bind:src="img_url + '' + itemCmt.userDetails.user_image" v-bind:alt="itemCmt.userDetails.name" />
                    </div>
                </div>
                <div class="w-100 ps-0">
                    <div class="mb5 mb-2 d-flex align-items-center justify-content-between">
                        <a href="javascript:;" class="dark strong">{{ itemCmt.userDetails.name }}</a>
                        <!-- <small><span class="text-off"> {{ dateChangeFormat(itemCmt.createdAt) }}</span></small> -->
                        <small>
                            <!-- <span class="text-off" v-bind:title="dateChangeFormat(itemCmt.createdAt)">  -->
                            <span class="text-off"  v-tooltip="dateChangeFormat(itemCmt.createdAt)"> 
                                <timeago :datetime="itemCmt.createdAt"/>
                            </span>
                        </small>
                    </div>
                    <div class="despOP_despton" v-html="itemCmt.description"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from "jquery";
import axios from 'axios'
import moment from 'moment'
// import ComponentLoader from '../../Widgets/Icon/ComponentLoader.vue'
import CommentTaskPreSvgIcon from '@/components/Widgets/Icon/CommentTaskPreSvgIcon'
export default {
    name: 'TaskCommentPage',
    components: {
        // ComponentLoader,
        CommentTaskPreSvgIcon,
    },
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            taskCommentsData: [],
            comLoader: 1,
        }
    },
    methods: {
        async getTaskComment() {
            // $('.loadimg').show();
            this.comLoader = 1 
            this.taskCommentsData = [];
            let task_id = $('.taskValId').val();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'task/get-task-comments', {
                task_id: task_id
            })
            .then((resp) => {
                this.comLoader = 2 
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.taskCommentsData = resp.data.data.comments
                    // $('.loadimg').hide();
                }
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                // this.errorRes = error.response.data.message;
            })
        },
        dateChangeFormat(dateVal) {
            return moment(dateVal).format('DD-MM-YYYY hh:mm A');
        },
    },

}
</script>