<template>
    <!-- {{ projectId }} -->
    <div class="sectionclsbody setback_tsk_bg_kanban">
        <input type="hidden" class="projectIdCls" v-bind:value="projectId" >
        <div class="serach_controls_main tsk_controls_main_cls tsk_ui_back_bg">
            <div class="serach_controls_main_row1">
                <div class="tsk_top_filter_left">     
                    <div class="serach_controls me-2">
                        <SearchSvgIcon />
                        <input type="search" class="formmginput-control tsk_search" id="searchTxtId" placeholder="Search here" v-on:keyup="reloadSearch()">
                    </div>
                    <div class="serach_controls w-200 me-2 projectFilterCls">
                        <Multiselect 
                            v-model="filterProjectIds" 
                            mode="multiple" 
                            :hide-selected="false" 
                            :caret="true" 
                            :searchable="true" 
                            :create-option="true" 
                            :options="$store.state.project.ProjectFilterList" 
                            placeholder="Select Projects" 
                            v-bind:name="'filterProjectIds[]'" 
                            v-bind:id="'filterProjectIds'"
                            @change="myChangeProjectEvent($event)"  />
                    </div>
                    <div class="serach_controls w-200 me-2"> 
                        <Multiselect
                            v-model="filterUsersIds"
                            :caret="true" 
                            mode="multiple"
                            placeholder="Select Teams"
                            :searchable="true"
                            v-bind:name="'filterUsersIds[]'"
                            v-bind:id="'filterUsersIds'" 
                            track-by="label"
                            label='label'
                            :close-on-select="false"
                            @change="myChangeUsersEvent($event)"
                            :hide-selected="false"
                            :options="$store.state.teamMember.TeamMemberImgFilterList"
                        >
                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                            <div
                                class="multiselect-tag is-user"
                                :class="{
                                    'is-disabled': disabled
                                }"
                            >
                                <div v-if="option.initials == null">  
                                    <img :src="img_url + '' + option.user_image">
                                    {{ option.label }}
                                </div>
                                <div v-else>  
                                    {{ option.initials }} {{ option.label }}
                                </div>
                                <span
                                v-if="!disabled"
                                class="multiselect-tag-remove"
                                @mousedown.prevent="handleTagRemove(option, $event)"
                                >
                                <span class="multiselect-tag-remove-icon"></span>
                                </span>
                            </div>
                            </template>
                            <template v-slot:option="{ option }">
                                <div v-if="option.initials == null">  
                                    <img class="user-image" :src="img_url + '' + option.user_image" >  {{ option.label }}
                                </div>
                                <div v-else>
                                    <span class="empIniName">
                                        {{ option.initials }} 
                                    </span>
                                    {{ option.label }}
                                </div>
                            </template>
                        </Multiselect>
                    </div> 
                    <div class="serach_controls w-200 me-2"> 
                        <Multiselect 
                            v-model="filterLabelsIds" 
                            mode="multiple" 
                            :hide-selected="false" 
                            :caret="true" 
                            :searchable="true" 
                            :create-option="true" 
                            :options="$store.state.label.LabelFilterList" 
                            placeholder="Select Labels" 
                            v-bind:name="'filterLabelsIds[]'" 
                            v-bind:id="'filterLabelsIds'"
                            @change="myChangeLabelsEvent($event)"  />
                    </div>
                </div>
                <div class="tsk_top_filter_right">                       
                    <div class="export_controls shimmer-wrapper">
                        <div class="filterToggle shimmer-line-xx shimmer-animate"  v-if="kanbanLoader == 1">
                           
                        </div>
                        <a href="javascript:;" class="filterToggle" v-on:click="filterOpen()" v-else>
                            <FilterSvgIcon />
                        </a>
                    </div>
                </div>
            </div>
            <div class="serach_controls_main_row2 mt-2" style="display: none;">
                <div class="serach_controls me-2"> 
                    <Multiselect 
                        v-model="filterPriorityIds" 
                        mode="multiple" 
                        :hide-selected="false" 
                        :caret="true" 
                        :searchable="true" 
                        :create-option="true" 
                        :options="$store.state.priority.PriorityFilterList" 
                        placeholder="Select Priority" 
                        v-bind:name="'filterPriorityIds[]'" 
                        v-bind:id="'filterPriorityIds'"
                        @change="myChangePriorityEvent($event)"  />
                </div>
                
                <div class="serach_controls me-2"> 
                    <Multiselect 
                        v-model="filterStatusIds" 
                        mode="multiple" 
                        :hide-selected="false" 
                        :caret="true" 
                        :searchable="true" 
                        :create-option="true" 
                        :options="$store.state.board.BoardFilterList" 
                        placeholder="Select Board Status" 
                        v-bind:name="'filterStatusIds[]'" 
                        v-bind:id="'filterStatusIds'"
                        @change="myChangeStatusEvent($event)"  />
                </div>
            </div>
        </div>
        <div class="kanbanBody">
            <div class="d-flex w-100 justify-content-center shimmer-wrapper" v-if="kanbanLoader == 1">
                <div class="card1 tsk_column flex justify-center redCls" v-for="index in 4" :key="index">
                    <div class="box">
                        <div class="boxHeaderCls">
                            <h2 class="float-left shimmer-line shimmer-line-w30 shimmer-animate"></h2>
                            <h6 class="taskKanbanCls float-right shimmer-line-w20 shimmer-line shimmer-animate"></h6>
                        </div>      
                        <div class="sdssdsad"> 
                            <div class="boxbodyCls list-group w-full">
                                <div class="list-group-item tsk_item_card_cls boardClsId" v-for="index in 5" :key="index">
                                    <div class="itemMainClsSim taskTitleClsSim">
                                        <div class="kanTitleCls">
                                            <span class="shimmer-line-w40 shimmer-line shimmer-animate"></span>
                                            <b class="shimmer-line-w20 shimmer-line shimmer-animate"></b>
                                        </div>
                                        <div class="kanPriNameCls">
                                            <div class="shimmer-line-w20 shimmer-line shimmer-animate"></div>     
                                        </div>
                                        <div class="kanProNameCls">
                                            <div class="shimmer-line-full shimmer-line shimmer-animate"></div>     
                                        </div>
                                        <div class="kanPriFooterCls d-flex align-items-center"> 
                                            <div class="kanFooterCmt_right w-100">
                                                <div class="kanFooterDateCls float-left ms-0 shimmer-line-w10 shimmer-line shimmer-animate">
                                                </div>
                                                <div class="kanFooterCmtCls float-left  ms-2 shimmer-line-w10 shimmer-line shimmer-animate">
                                                </div>
                                                <div class="kanFooterAttCls float-left  ms-2 shimmer-line-w10 shimmer-line shimmer-animate">
                                                </div>
                                            </div>
                                            <div class="kanFooterCmt_left">
                                                <div class="kanTitleClssdsd d-flex">
                                                    <div class="mg-thumbnail shimmer-line shimmer-animate me-1"></div>
                                                    <div class="mg-thumbnail shimmer-line shimmer-animate me-0"></div>     
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="d-flex w-100 justify-content-center" v-else-if="kanbanLoader === 2">
                <ComponentNoData />
            </div>
            <div class="d-flex w-100 justify-content-center" v-else>
                <div class="card1 tsk_column flex justify-center redCls" v-for="(item) in kanbanData" :key="item._id" >
                    <div class="box">
                        <div class="boxHeaderCls ">
                            <h2 class="float-left">{{item.board_status_name}}</h2>
                            <h6 v-bind:class="'statusCls-' + item._id" class="taskKanbanCls float-right" v-bind:data-BoardId="item._id" >{{item.data_count }}</h6>
                        </div>      
                        <div class="sdssdsad">                 
                            <draggable class="dragArea boxbodyCls list-group w-full " :list="item.data" :group="{ name: 'people'}"
                                :sort="true" @change="log" :move="checkMove">
                                <KanbanItem :item="item"/>                            
                            </draggable>
                        </div> 
                    </div>
                </div>
            </div>   
        </div>
    </div>
    <input type="hidden" class="taskValId" id="taskValId"/>
    <a href="javascript:;" class="editTasksBtns" ></a>

    <AddEditLabel @reload-table-data="reloadSearch"/>
    <AddEditTask @reload-table-data="reloadSearch" ref="childComponentTaskRef"/>
    <PreviewTask @reload-table-data="reloadSearch" ref="childComponentPreviewTaskRef"/>
</template>
<script>
import { VueDraggableNext } from 'vue-draggable-next'
import axios from 'axios'
import PreviewTask from './List/PreviewTask.vue'
import $ from "jquery";
import Multiselect from '@vueform/multiselect'
import AddEditTask from './List/AddEditTask.vue'
import AddEditLabel from '../Label/AddEditLabel.vue'
import SearchSvgIcon from '../Widgets/Icon/SearchSvgIcon.vue'
import FilterSvgIcon from '../Widgets/Icon/FilterSvgIcon.vue'
// import ActionSvgIcon from '../Widgets/Icon/ActionSvgIcon.vue'
import KanbanItem from './kanbanItem.vue'
import ComponentNoData from '../Widgets/Icon/ComponentNoData.vue';

import {
    ref
} from 'vue';
export default {
    name: 'karbanPage',
    components: {
        draggable: VueDraggableNext,
        PreviewTask,
        Multiselect,
        AddEditTask,
        AddEditLabel,
        SearchSvgIcon,
        FilterSvgIcon,
        // ActionSvgIcon,
        KanbanItem,
        ComponentNoData,
    },
    props: {
        projectId: {
            type: String,
        },
        msg: String
    },
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            enabled: true,
            kanbanData: [],
            filterPriorityIds: [],
            filterProjectIds: [],
            filterLabelsIds: [],
            filterStatusIds: [],
            filterUsersIds: [],
            kanbanLoader: 1,
            dragging: false,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        }
    },
    async mounted() {
        this.$store.state.pageTitle = 'Tasks-Kanban'
        if ($('.projectIdCls').val().length !== 0) {
            $('.projectFilterCls').hide();
        }
        if (this.$store.state.isActiveToken != '1') {
            // console.log(this.currentDate);
        } else {
            await this.loadKandanData();
        }
        let temps = this
        $("body").on("click", '.taskTitleCls', function () {
            var taskId = $(this).attr('dataid');
            temps.modalViewOpen(taskId)
        });
        $("body").on("click", '.editTasksBtns', function () {
            var task_id = $('.taskValId').val();
            temps.modalEditOpen(task_id);
        });
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    computed: {
        iAT() {
            return this.$store.state.user.roleId
        },
    },
    watch: {
        async iAT(newValue) {
            if (this.$store.state.user.roleId != '') {
                await this.loadKandanData();
            }
            return newValue
        },
    },
    methods: {
        checkHeightScroll() {
            $('.kanbanBody').removeClass('ScrollAddedCls');
            if (this.hasScrollBar() == true) {
                $('.kanbanBody').addClass('ScrollAddedCls');
            }
        },
        hasScrollBar() {
            if ($(".tbodyCls")[0]) {
                return $('.tbodyCls').get(0).scrollHeight > $('.tbodyCls').height() + 10;
            } else {
                return false;
            }
        },
        onResize() {
            this.windowWidth = window.innerWidth
            this.windowHeight = window.innerHeight

            var headNavCls = $('.headNavCls').outerHeight() || 0;
            var tsk_tabs = $('.tsk_tabs').outerHeight() || 0;
            var tsk_controls_main_cls = $('.tsk_controls_main_cls').outerHeight() || 0;
            var thead = $('thead').outerHeight() || 0;
            var databottom = $('.databottom').outerHeight() || 0;

            var cntSubvar = headNavCls + tsk_tabs + tsk_controls_main_cls + thead + databottom

            cntSubvar = this.windowHeight - cntSubvar - 30
            var cardheighvar = cntSubvar - 48

            $('.kanbanBody').height(cntSubvar);
            $('.boxbodyCls').height(cardheighvar);
            
            this.checkHeightScroll();

        },
        async filterOpen() {
            $('.serach_controls_main_row2').slideToggle()
            $('.filterToggle').toggleClass('active')
            // await this.onResize()
            setTimeout(async () =>
                this.onResize(),
            500);
        },
        
        startLoader() {
            this.$Progress.start()
        },
        finishLoader() {
            this.$Progress.finish()
        },
        async taskItemCnt() {
            $(".taskKanbanCls").each(function () {
                let boardid = $(this).attr('data-boardid');
                let numItems = $('.statusTaskItemCls-'+ boardid).length
                $(this).html(numItems)
            });
        },
        async reloadSearch() {
            const searchCls = document.getElementById('searchTxtId').value;
            this.searchKey = searchCls.trim();
            this.page = 1;
            this.loadKandanData();
        },
        myChangeProjectEvent(val) {
            this.filterProjectIds = val
            this.page = 1;
            this.loadKandanData();
        },
        myChangePriorityEvent(val) {
            this.filterPriorityIds = val
            this.page = 1;
            this.loadKandanData();
        },
        myChangeLabelsEvent(val) {
            this.filterLabelsIds = val
            this.page = 1;
            this.loadKandanData();
        },
        myChangeStatusEvent(val) {
            this.filterStatusIds = val
            this.page = 1;
            this.loadKandanData();
        },
        myChangeUsersEvent(val) {
            this.filterUsersIds = val
            this.page = 1;
            this.loadKandanData();
        },
        add() {
            console.log('add')
        },
        /* replace() {
            console.log('replace')
        }, */
        checkMove(event) {
            // console.log('checkMove', event.draggedContext)
            // console.log('Future index: ' + event.draggedContext.futureIndex)
            console.log('checkMove event: ', event)
            // console.log('board_status_name: ', event.draggedContext.element.board_status_name)
        },
        log(event) {
            const { moved, added} = event
            
            if (moved) console.log('moved', moved)
            if (added){
                var bId = $('.taskId-'+added.element.task_id).closest('.boardClsId').attr('data-boardid')
                this.taskItemCnt();
                console.log('bId -> '+ bId)
                this.changeBoard(bId, added.element.task_id, added.newIndex)
            }
        },
        readMore(string, task_id) {
            console.log(task_id)
            let strLength = string.length;
            var resultStr = string;
            if(strLength > 24){
                resultStr = string.substring(0,20)+'<span title="'+string+'">...</span>';
                // resultStr = '<a href="javascript:;" class="taskTitleCls" data-id="'+task_id+'">'+resultStr+'</a>'

            }else{
                // resultStr = '<a href="javascript:;" class="taskTitleCls" data-id="'+task_id+'">'+string+'</a>'
            }
            return '<span title="'+string+'">'+resultStr+'</span>';
        },
        
        labelsHtml(labels) {
            var len = labels.length;
            var htmls = '';
            for (var i = 0; i < len; i++) {
                    htmls += '<div class="badge  " style="background-color: ' + labels[i].color_code + '">';
                        htmls += '<span title="'+labels[i].label+'" class="labelSpanCls"  >'+labels[i].label+'</span>';
                    htmls += '</div>';
            }
            return htmls;
        },
        async loadKandanData() {
            this.kanbanLoader = 1
            var filterProjectIds = []
            if ($('.projectIdCls').val().length !== 0) {
                filterProjectIds.push(this.projectId);
            } else {
                filterProjectIds = this.filterProjectIds
            }
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.get(process.env.VUE_APP_API_URL + 'task/kanban-list', {
                    params: {
                        project_id: filterProjectIds,
                        // project_id: this.projectId,
                        priorityIds: this.filterPriorityIds,
                        labelIds: this.filterLabelsIds,
                        statusIds: this.filterStatusIds,
                        userIds: this.filterUsersIds,
                        searchKey: this.searchKey,
                    }
                })
                .then((resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.kanbanData = []
                        this.kanbanData = resp.data.data.records
                        if (this.kanbanData.length > 0) {
                            this.kanbanLoader = 3
                        } else {
                            this.kanbanLoader = 2
                        }
                        setTimeout(async () =>
                            this.onResize(),
                        500);
                        
                    }else{
                        this.$store.state.user.accessToken = ''
                        this.$store.state.isActiveToken = ''
                        this.$store.state.user.userInfo = ''
                        localStorage.removeItem("userInfo");
                        localStorage.removeItem("mgTasktoken");
                        this.$router.push({
                            name: 'Login'
                        })
                    }
                })
                .catch(error => {
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    // this.errorRes = error.response.data.message;
                })
        },
        async changeBoard(boardId, taskId, newIndex) {
            this.startLoader()
            console.log(newIndex);
            this.formLoader = 2
            let _post = {}
            let _URL = ''
            _post = {
                board_status_id: boardId,
                task_id: taskId,
                // newIndex: newIndex,
            }
            _URL = process.env.VUE_APP_API_URL + 'task/change-board'
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
           
            await axios.post(_URL, _post)
                .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.$toast.success(resp.data.message);
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
                    this.formLoader = 1
                    this.taskItemCnt();
                    this.finishLoader()
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
            })   
        },
        async modalViewOpen(task_id) {
            $('.taskValId').val(task_id);
            this.callChildPreviewMethod();
        },
        async modalEditOpen(task_id) {
            $('.taskValId').val(task_id);
            this.callChildProjectMethod();
        },
    },
    setup() {
        const childComponentPreviewTaskRef = ref(null);
        const callChildPreviewMethod = () => {
            if (childComponentPreviewTaskRef.value) {
                childComponentPreviewTaskRef.value.modalViewOpen();
            }
        };
        const childComponentTaskRef = ref(null);
        const callChildProjectMethod = () => {
            if (childComponentTaskRef.value) {
                childComponentTaskRef.value.modalEditOpen();
            }
        };

        return {
            callChildProjectMethod,
            childComponentTaskRef,
            callChildPreviewMethod,
            childComponentPreviewTaskRef,
        };
    },
}
</script>

<!-- <style scoped>
    .justify-center {
    justify-content: center;
    }
    .flex {
    display: flex;
    }
    .mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    }
    .w-64 {
    width: 16rem;
    }
    .px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    }
    .list-group {
    min-height: 20px;
    }
    .p-3 {
    padding: 0.75rem;
    }
    .m-1 {
    margin: 0.25rem;
    }
    .rounded-md {
    border-radius: 0.375rem;
    }
    .bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210,214,220,var(--bg-opacity));
    }
    .p-5 {
    padding: 1.25rem;
    }
    pre {
    font-size: 15px;
    text-align: start;
    }
    .rounded-lg {
    border-radius: 0.5rem;
    margin: 0 10px;
    }
    
    .greenCls .bg-gray-300 {
        background-color: #1eff00 !important;
    }
    .card1{
        border: 1px solid rgb(214, 193, 193);
        margin: 5px;
        border-radius: 7px;
    }
    .clearfix{
        clear: both;
    }
    .kanTitleCls{
        padding: 10px;
    }
    .mg-thumbnail span.labelSpanCls{
        color:  white;
    }
    .multiselect-tag.is-user {
        padding: 5px 8px;
        border-radius: 22px;
        background: #35495e;
        margin: 3px 3px 8px;
    }

    .multiselect-tag.is-user img {
        width: 18px;
        border-radius: 50%;
        height: 18px;
        margin-right: 8px;
        border: 2px solid #ffffffbf;
    }

    .multiselect-tag.is-user i:before {
        color: #ffffff;
        border-radius: 50%;
        ;
    }

    .user-image {
        margin: 0 6px 0 0;
        border-radius: 50%;
        height: 22px;
    }
</style> -->