<template>
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#taskPreviewModals" id="previewTaskBtn"> </button>
    <div class="modal fade right add_edit_rtaksset_" id="taskPreviewModals" tabindex="-1" style="display: none;" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-xl ">
            <div class="modal-content">
                <div class="modal-header">
                    <input type="hidden" name="taskEditId" id="taskEditId">
                    <h5 class="modal-title"> Task <b>#{{ taskData.task_no }}</b></h5>  
                    <span class="fw-bold"> 
                        <!-- <a href="javascript:;" class="mg-btn-gold me-2" v-on:click="editTask(taskData._id)"> 
                            Edit Task
                        </a> -->
                        <button type="button" class="btn-close previewClosePop" id="closePop" data-bs-dismiss="modal" aria-label="Close">
                        </button>                    
                    </span>
                </div>
                <div class="modal-body setformbackform editviewmode">
                    <div class="row">
                        <div class="col-sm-12" v-if="taskData.is_deleted == true">
                            <div class="formmginput-controlx newset_bo_archived">
                                <p class="fw-title"> 
                                    <span>                                           
                                        <ArchiveSvgIcon />
                                    </span>
                                    <b>This card is archived.</b>
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <div class="formmginput-controlx mb-2"> 
                                <p class="fw-title">
                                    <span>
                                        <TitleTaskPreSvgIcon /> 
                                    </span>
                                    <!-- <b contenteditable="true">{{ taskData.title }}</b> -->
                                    <input type="text" class="taskPreTitle titTksCls " v-bind:value="taskData.title" @focusin="handleBlur" @focusout="handleFocusout"/>
                                    <input type="hidden" class="taskPreTitleHid " v-bind:value="taskData.title" />
                                    <!-- <input type="text" class="taskPreTitle titTksCls " v-bind:value="taskData.title" v-on:click="editTitleTask()"/> -->
                                </p> 
                                <!-- <p contenteditable="true">{{ taskData.title }}</p> -->
                                <p class="lsit_setback">in list {{ taskData.boardstatus }}</p>
                                <p class="lsit_setback colorst">
                                    <!-- {{ taskData.description }} -->
                                    <textarea class="taskPreDesc descTksCls " v-bind:value="taskData.description" @focusin="handleBlurDesc" @focusout="handleFocusoutDesc"></textarea>
                                    <textarea class="taskPreHidDesc d-none" v-bind:value="taskData.description"></textarea>
                                </p> 
                            </div> 
                            <div class="formmginput-controlx setprojectviewchip mb-2 mrleftx"> 
                                <p><span class="fw-bold">Project: </span> <span class="fw-normal">{{ taskData.projects }}</span> </p> 
                            </div> 
                            <div class="descriptionss_cls_tsk d-flex flex-column">
                                <p class="fw-title">
                                    <span>                                        
                                        <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="3" y="2" width="26" height="4" rx="2" fill="#44546F"/>
                                        <rect x="3" y="9" width="22" height="4" rx="2" fill="#44546F"/>
                                        <rect x="3" y="16" width="18" height="4" rx="2" fill="#44546F"/>
                                        </svg>
                                    </span>
                                    <b>Add Comment</b>
                                </p> 
                                <div class="text_back_area_cls dummytxtarayCls">
                                    <span v-on:click="openCmtBox()">Add a more detailed description…</span>
                                </div>
                            </div>
                            <form @submit.prevent="onSubmit" class="taskFrm fltformmanagedm d-none" id="taskFrm">
                                <div class="formmginput-controlx mb-2"> 
                                    <VueEditor v-model="v$.form.description.$model"/>    
                                </div> 
                                <div class="modal-footer pe-0 ps-0 border-0">                           
                                    <button type="button" class="mg-btn-gold spaceh mg-btn-cancel me-2" id="cancalUpdateBtn" v-on:click="openCmtBox()">Cancel</button>
                                    <button type="button" class="mg-btn-gold" id="saveUpdateBtn" :disabled="v$.form.$invalid" v-on:click="addCommentTasks" v-if="formLoader === 1">{{ buttonName }}</button>
                                    <button type="button" class="mg-btn-gold" id="saveUpdateBtn" :disabled="v$.form.$invalid" v-else><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...</button>
                                </div>
                            </form>
                            
                            <TodoListTask @reload-activity-data="reloadActivity" ref="childComponentTaskTodoRef" />
                            <CommentTask ref="childComponentTaskCommentRef" />
                            <AttachmentTask ref="childComponentTaskAttachmentRef" />
                            <ActivityTask ref="childComponentTaskActivityRef"/>
                        </div>
                        <div class="col-sm-3">
                            <div class="set_border_group">
                                <div class="formmginput-controlx mb-2"> 
                                    <span class="fw-bold">Priority:</span> 
                                    <Select2 
                                        v-model="priorityData" 
                                        v-bind:id="'priorityDropId'" 
                                        :options="$store.state.priority.PriorityList" 
                                        @select="myChangePriority($event)" 
                                        :placeholder="'Select Priority'" 
                                        :settings="{ dropdownParent: '#taskPreviewModals' }" />
                                </div> 
                                <div class="formmginput-controlx mb-2"> 
                                    <span class="fw-bold">Label:</span> 
                                    <Multiselect v-model="labelsData" mode="multiple" :hide-selected="false" :caret="false" :searchable="true" :create-option="true" :options="$store.state.label.LabelFilterList" placeholder="Select labels" v-bind:name="'labelsId[]'" v-bind:id="'labelsFilterId'" @change="myChangeLabel($event)"/>                                
                                    <div v-html='labelsHtml($store.state.labelsVal)' /> 
                                </div> 
                            </div>
                            <div class="set_border_group">
                                <div class="formmginput-controlx mb-2"> 
                                    <span class="fw-bold">Assign to:</span>
                                    <Multiselect v-bind:name="'teamId[]'" v-bind:id="'teamFilterId'" v-model="teamUserData" mode="multiple"
                                        placeholder="Select teams" track-by="name" label="name" :close-on-select="false" :search="true"
                                        :hide-selected="false"
                                        @change="myChangeUsers($event)" :options="$store.state.teamMember.TeamMemberImgFilterList
                                        ">
                                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                            <div class="multiselect-tag is-user" :class="{'is-disabled': disabled}">
                                                <div v-if="option.initials == null">  
                                                    <img :src="img_url + '' + option.user_image">
                                                    {{ option.label }}
                                                </div>
                                                <div v-else>  
                                                    {{ option.initials }} {{ option.label }}
                                                </div>
                                                <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                                                <span class="multiselect-tag-remove-icon"></span>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:option="{ option }">
                                                <div v-if="option.initials == null">  
                                                    <img class="user-image" :src="img_url + '' + option.user_image" >  {{ option.label }}
                                                </div>
                                                <div v-else>  
                                                    {{ option.initials }} {{ option.label }}
                                                </div>
                                        </template>
                                    </Multiselect>                                
                                    <div class="mt-2 d-flex flex-wrap align-items-center" v-html='assignToHtml($store.state.assignToVal)' /> 
                                </div> 
                            </div>
                            <!-- <div class="formmginput-controlx mb-2"> 
                                <p><span class="fw-bold">Status:</span> <span class="fw-normal">{{ taskData.boardstatus }}</span> </p> 
                            </div>  -->
                            <div class="to_do_list_box_setUI mt-1">
                                <div class="formmginput-controlx mb-2"> 
                                    <span class="fw-bold btn_setbox">
                                        <a href="javascript:;" class="clickbxc" v-on:click="openTodoBox()">
                                            <span>
                                                <ChecklistSvgIcon />
                                            </span>
                                            Todo List 
                                        </a>
                                    </span>
                                </div>
                                <div class="formmginput-controlx mb-2  todoBoxCls" style="display:none"> 
                                    <div class="todo-inner setformbackform" role="tooltip">
                                        <!-- <div class="todo-title">Todo List</div> -->
                                        <div class="todo-inner-content">
                                            <div class="todoContent">
                                                <input placeholder="Todo Title" class="input form-control mb-2" type="text" value="" id="todoTitle" v-on:keyup="checkTodoTxt()">
                                                <button type="button" class="mg-btn-gold me-2" v-if="formTodoLoader === 1" disabled id="todoBtn" v-on:click="addTodoTxt()"><span>Add List</span> </button>
                                                <button type="button" class="mg-btn-gold me-2" id="saveTodoBtn" v-else><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</button>
                                                <a href="javascript:;" class="hiddetails h33" v-on:click="closeTodoBox()">Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="formmginput-controlx mb-2"> 
                                <span class="fw-bold btn_setbox">
                                    <a href="javascript:;" class="clickbxc" v-on:click="openAttachmentBox()" accept="image/*">
                                        <span>
                                            <AttachmentSvgIcon />
                                        </span>
                                        Attachment 
                                    </a>
                                </span>
                                <input type="file" id="fileAttachment" multiple="" style="display: none;" v-on:change="adddAttachmentBox()" >
                            </div>
                            <div class="formmginput-controlx mb-2 to_do_list_box_setUI backRedCls" v-if="taskData.is_deleted == true"> 
                                <span class="fw-bold btn_setbox">
                                    <a href="javascript:;" class="clickbxc" v-on:click="openRestoreBox(taskData._id)">
                                        <span>                                           
                                            <RestoreSvgIcon />
                                        </span>
                                        Back to Task 
                                    </a>
                                </span>
                            </div>
                            <div class="formmginput-controlx mb-2 to_do_list_box_setUI archieveboxUI" v-if="taskData.is_deleted == false"> 
                                <span class="fw-bold btn_setbox">
                                    <a href="javascript:;" class="clickbxc" v-on:click="openArchiveBox()">
                                        <span>                                           
                                            <ArchiveSvgIcon />
                                        </span>
                                        Archive 
                                    </a>
                                </span>
                                <div class="formmginput-controlx mb-2  ArchiveBoxCls todoBoxClsx mt-2" style="display:none"> 
                                    <div class="todo-inner setformbackform" role="tooltip">
                                        <div class="todo-inner-contentx">
                                            <div class="todoContentx">
                                                <span class="arwedsure">Are you sure you want to Archive this card?</span>
                                                <div class="d-flex justify-content-center w-100">
                                                    <button type="button" class="mg-btn-gold me-2" v-on:click="addArchive()"><span>Yes</span> </button>
                                                    <a href="javascript:;" class="hiddetails h33" v-on:click="closeArchiveBox()">Cancel</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> 
<script>
import $ from "jquery";
import axios from 'axios'
import { VueEditor } from "vue3-editor"
import useVuelidate from '@vuelidate/core'
import CommentTask from './CommentTask.vue'
import ActivityTask from './ActivityTask.vue'
import AttachmentTask from './AttachmentTask.vue'
import TodoListTask from './TodoListTask.vue'
import Select2 from 'vue3-select2-component';
import ChecklistSvgIcon from '../../Widgets/Icon/ChecklistSvgIcon.vue'
import TitleTaskPreSvgIcon from '@/components/Widgets/Icon/TitleTaskPreSvgIcon'
import RestoreSvgIcon from "@/components/Widgets/Icon/RestoreSvgIcon.vue";
import ArchiveSvgIcon from '@/components/Widgets/Icon/ArchiveSvgIcon'
import {
    required,
    minLength,
} from '@vuelidate/validators'
import {
    ref
} from 'vue';
import Multiselect from '@vueform/multiselect'
import AttachmentSvgIcon from "@/components/Widgets/Icon/AttachmentSvgIcon.vue";
export default {
    name: 'PreviewTaskPage',
    components: {
        Select2,
        VueEditor,
        CommentTask,
        ActivityTask,
        AttachmentTask,
        TodoListTask,
        ChecklistSvgIcon,
        Multiselect,
        TitleTaskPreSvgIcon,
        RestoreSvgIcon,
        ArchiveSvgIcon,
        AttachmentSvgIcon
    },
    emits: ["reload-table-data"],
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            taskData: [],
            teamUserData: [],
            priorityData: [],
            labelsData: [],
            modalType: 'Add',
            buttonName: 'Save',
            errorMessage: '',
            todoTitle: '',
            formLoader: 1,
            formTodoLoader: 1,
            todoOpen: 1,
            archiveOpen: 1,
            teamOpen: 1,
            priorityOpen: 1,
            labelOpen: 1,
            loadModal: 1,
            attachmentFiles: [],
            form: {
                description: '',
            },
        }
    },
    async mounted() {
        // 
    },
    methods: {
        async reloadActivity() {
            await this.getTaskActivity();
        },
        startLoader() {
            this.$Progress.start()
        },
        finishLoader() {
            this.$Progress.finish()
        },
        handleBlur(e) {
            $('.titTksCls').toggleClass('is-editing');
            console.log('blur', e.target.placeholder)
        },
        handleFocusout() {
            $('.titTksCls').toggleClass('is-editing');
            let titleVal = $('.titTksCls').val();
            let titleHidVal = $('.taskPreTitleHid').val();
            titleVal = titleVal.trim();
            if (titleVal.length > 0) {
                $(".titTksCls").removeClass('error')
                $(".titTksCls").removeClass('errorCls')
                if (titleHidVal != titleVal) {
                    this.editTaskTitle();
                }
            } else {
                $(".titTksCls").focus()
                $(".titTksCls").addClass('error')                
                $(".titTksCls").addClass('errorCls')                
            }
        },
        handleBlurDesc(e) {
            $('.descTksCls').toggleClass('is-editing-desc');
            console.log('blur', e.target.placeholder)
        },
        handleFocusoutDesc() {
            $('.descTksCls').toggleClass('is-editing-desc');
            let titleVal = $('.descTksCls').val();
            let titleHidVal = $('.taskPreHidDesc').val();

            titleVal = titleVal.trim();
            titleHidVal = titleHidVal.trim();
            console.log('titleVal ->'+titleVal)
            console.log('titleHidVal ->'+titleHidVal)
            if (titleVal.length > 0) {
                $(".descTksCls").removeClass('error')
                $(".descTksCls").removeClass('errorCls')
                if (titleHidVal != titleVal) {
                    this.editTaskTitle();
                }
            } else {
                $(".descTksCls").focus()
               $(".descTksCls").addClass('error')                
                $(".descTksCls").addClass('errorCls')                
            }
        },
        editTitleTask() {
            $('.titTksCls').toggleClass('is-editing');
            // $('.titTksCls').toggleClass('d-none');
        },
        openCmtBox() {
            $('.fltformmanagedm').toggleClass('d-none')
            $('.dummytxtarayCls').toggleClass('d-none')
            this.form.description = '';
        },
        async editTaskTitle() {
            if ($(".errorCls").length) {
                console.log('Not Save')
            } else {
                let _URL = ''
                let task_id = $('.taskValId').val();
                let description = $('.descTksCls').val();
                this.startLoader()
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                let _post = {}
                let titleVal = $('.titTksCls').val();
                _URL = process.env.VUE_APP_API_URL + 'task/minimal-update'   
                _post = {
                    task_id: task_id,
                    description: description,
                    title: titleVal,
                }
                await axios.post(_URL, _post)
                    .then((resp) => {
                        this.finishLoader()
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.$toast.success(resp.data.message);
                        // this.$emit('reload-table-data');
                        $(".kanTitCls-" + task_id).html(titleVal)
                        
                        $('.taskPreTitleHid').val(titleVal);
                        $('.taskPreHidDesc').val(description);
                        // document.getElementsByClassName("kanTitCls-"+task_id).html = titleVal;
                    } else if (resp.status == 200 && resp.data.status == 'failure') {
                        this.errorMessage = resp.data.message;
                    }
                })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.errorRes = error.response.data.message;
                })            
                
            }
        },
        async myChangeUsers(val) {
            if (this.loadModal == 2) {
                this.startLoader()
                this.formLoader = 2
                let _URL = ''
                
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                let _post = {}
                let task_id = $('.taskValId').val()

                _URL = process.env.VUE_APP_API_URL + 'task/update-user'   
                _post = {
                    task_id: task_id,
                    user_id: val
                }
                
                await axios.post(_URL, _post)
                    .then(async (resp) => {
                        this.finishLoader()
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.$store.state.assignToVal = resp.data.data.users
                        this.$toast.success(resp.data.message);
                        await this.getTaskActivity();
                        const userImg = this.userImgHtml(resp.data.data.users)
                        $('.kanUsersCls-'+task_id).html(userImg)
                        // this.$emit('reload-table-data');
                    } else if (resp.status == 200 && resp.data.status == 'failure') {
                        this.errorMessage = resp.data.message;
                    }
                        this.formLoader = 1
                })
                    .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.errorRes = error.response.data.message;
                    this.formLoader = 1
                }) 
            }
        },
        userImgHtml(users) {
            var len = users.length;
            var htmls = '';
            htmls += '<div >';
            for (var i = 0; i < len; i++) {
                if (len <= 3 || i < 2) {
                    if (users[i].initials == null) {
                        htmls += '<div class="mg-thumbnail">';
                        htmls += '<img src="' + this.img_url + '' + users[i].user_image + '" alt="' + users[i].name + '" title="' + users[i].name + '" />';
                        htmls += '</div>';
                    } else {
                        htmls += '<div class="mg-thumbnail tsk_name">';
                        htmls += '<span title="' + users[i].name + '">' + users[i].initials + '</span>';
                        htmls += '</div>';
                    }
                }
            }
            if (len > 2 && len > 3) {
                htmls += '<div class="mg-thumbnail tsk_number">';
                var cntVal = len - 2;
                htmls += '+' + cntVal;
                htmls += '</div>';
            }
            htmls += '</div>';
            return htmls;
        },
        // labelsHtml(labels) {
        //     var len = labels.length;
        //     var htmls = '';
        //     for (var i = 0; i < len; i++) {
        //             htmls += '<div class="tsk_priority" style="background-color: ' + labels[i].color_code + '">';
        //                 htmls += '<span title="'+labels[i].label+'" class="labelSpanCls">'+labels[i].label+'</span>';
        //             htmls += '</div>';
        //     }
        //     return htmls;
        // },
        async myChangeLabel(val) {
            if(this.loadModal == 2){
                this.formLoader = 2
                let _URL = ''
                let task_id = $('.taskValId').val()
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                let _post = {}
    
                _URL = process.env.VUE_APP_API_URL + 'task/update-label'   
                _post = {
                    task_id: task_id,
                    label_id: val
                }
                
                await axios.post(_URL, _post)
                .then(async (resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.$store.state.labelsVal = resp.data.data.labels
                        this.$toast.success(resp.data.message);
                        // await this.getTaskActivity();
                        // this.$emit('reload-table-data');
                         const userImg = this.labelsHtml(resp.data.data.labels)
                        $('.kanLabelCls-'+task_id).html(userImg)
                    } else if (resp.status == 200 && resp.data.status == 'failure') {
                        this.errorMessage = resp.data.message;
                    }
                        this.formLoader = 1
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.errorRes = error.response.data.message;
                    this.formLoader = 1
                }) 

            }
        },
        async openTodoCheckCancelBox(todo_id) {
            $('.'+todo_id+'-todoItemTxtCls').val('');
            $('.'+todo_id+'-todoItemCls').toggle();
            $('.'+todo_id+'-todoItemAddCls').prop('disabled', true);
            $('.'+todo_id+'-todoItemBtnCls').toggle();
        },
        async closeTodoBox() {
            this.todoOpen = 1
            $('.todoBoxCls').hide()
        },
        async closeArchiveBox() {
            this.archiveOpen = 1
            $('.ArchiveBoxCls').hide()
        },
        async openArchiveBox() {
            if(this.archiveOpen == 2){                
                this.archiveOpen = 1
                $('.ArchiveBoxCls').hide()
            }else{
                this.archiveOpen = 2
                $('.ArchiveBoxCls').show()
            }
        },
        async openRestoreBox(task_id) {
            this.startLoader();
            let _URL = ''
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            let _post = {}
            _URL = process.env.VUE_APP_API_URL + 'task/restore'
            _post = {
                task_id: task_id,
            }
            await axios.post(_URL, _post)
            .then(async (resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.finishLoader()
                    this.taskData.is_deleted = false
                    this.$emit('reload-table-data');
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
            }) 
        },
        async addArchive() {
            this.archiveOpen = 1
            let _URL = ''
            let task_id = $('.taskValId').val();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            let _post = {}
            _URL = process.env.VUE_APP_API_URL + 'task/archive'
            _post = {
                task_id: task_id,
            }
            await axios.post(_URL, _post)
            .then(async (resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.$toast.success(resp.data.message);
                    $('.ArchiveBoxCls').hide()
                    this.taskData.is_deleted = true
                    await this.getTaskActivity();
                
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
                this.$emit('reload-table-data');
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
            })    
        },
        async openTodoBox() {
            $('#todoTitle').val('')
            this.todoTitle = '';
            this.formTodoLoader = 1
            if(this.todoOpen == 2){                
                this.todoOpen = 1
                $('.todoBoxCls').hide()
            }else{
                this.todoOpen = 2
                $('.todoBoxCls').show()
            }
        },
        async openAttachmentBox() {
            $('#fileAttachment').trigger('click')
        },
        async adddAttachmentBox() {
            this.startLoader()
            this.formLoader = 2
            let _URL = ''

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            const fd = new FormData;
            _URL = process.env.VUE_APP_API_URL + 'task-comment/attachments'
            fd.append('task_id', $('.taskValId').val())
            // fd.append('attachments', document.getElementById('fileAttachment').files[0])

            var files = document.getElementById('fileAttachment').files;

            for (var i = 0; i < files.length; i++) {
                fd.append('attachments', files[i]);
            }
            
            await axios.post(_URL, fd)
            .then(async (resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.$toast.success(resp.data.message);
                    await this.getTaskAttachment();
                    await this.getTaskActivity();
                    this.$emit('reload-table-data');
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                    this.$toast.error(resp.data.data[0]);
                }
                this.finishLoader()
                this.formLoader = 1
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
            })
        },
        async openTeamsBox() {
            if(this.teamOpen == 2){                
                this.teamOpen = 1
                $('.teamsBoxCls').hide()
            }else{
                this.teamOpen = 2
                $('.teamsBoxCls').show()
            }
        },
        async editTask(task_id) {
            this.startLoader();
            $('.taskValId').val(task_id);
            $('.previewClosePop').trigger('click')
            setTimeout(async () =>
                $('.editTasksBtns').trigger('click'),
            500);

        },
        async openPriority() {
            if(this.priorityOpen == 2){                
                this.priorityOpen = 1
                $('.priorityBoxCls').hide()
            }else{
                this.priorityOpen = 2
                $('.priorityBoxCls').show()
            }
        },
        async openLabel() {
            if(this.labelOpen == 2){                
                this.labelOpen = 1
                $('.labelBoxCls').hide()
            }else{
                this.labelOpen = 2
                $('.labelBoxCls').show()
            }
        },
        myChangePriority({
            id,
            text
        }) {
            console.log({
                id,
                text
            })
            $('.priorityTxtCls').text(text);
            this.updatePriorityData(id);
        },
        async updatePriorityData(id) {
            if(this.loadModal == 2){
                let _URL = ''
                let task_id = $('.taskValId').val();
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                let _post = {}
                _URL = process.env.VUE_APP_API_URL + 'task/change-priority'
                _post = {
                    priority_id: id,
                    task_id: task_id,
                }
                await axios.post(_URL, _post)
                .then(async (resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.$toast.success(resp.data.message);
                        const priClr = resp.data.data.color;
                        const priName = resp.data.data.priority;
                        var priHtml = '<div class="kanPriNameCls">';
                        priHtml += '<div style="background: ' + priClr + '">' + priName + '</div>';     
                        priHtml += '</div>';     
                        $('.kanPriCls-'+task_id).html(priHtml);
                        await this.getTaskActivity();
                    
                    } else if (resp.status == 200 && resp.data.status == 'failure') {
                        this.errorMessage = resp.data.message;
                    }
                    // this.$emit('reload-table-data');
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                })    
            }
        },
        async addTodoTxt() {
            const todoTitle = document.getElementById('todoTitle').value;
            this.todoTitle = todoTitle.trim();
            this.formTodoLoader = 1
            if(this.todoTitle.length > 0){
                this.formTodoLoader = 2
                let _URL = ''

                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
                let _post = {}
                _URL = process.env.VUE_APP_API_URL + 'task/add-todo-list'
                _post = {
                    todo_title: this.todoTitle,
                    task_id: $('.taskValId').val(),
                }
                await axios.post(_URL, _post)
                .then(async (resp) => {
                    if (resp.status == 200 && resp.data.status == 'success') {
                        this.$toast.success(resp.data.message);
                        $('#todoTitle').val('')
                        this.todoTitle = '';
                        this.getTaskTodo();
                        await this.getTaskActivity();
                    } else if (resp.status == 200 && resp.data.status == 'failure') {
                        this.errorMessage = resp.data.message;
                    }
                        this.formTodoLoader = 1
                        this.todoOpen = 1
                        $('.todoBoxCls').hide()
                })
                .catch(error => {
                    this.errorMessage = error.message;
                    console.error("There was an error!", error);
                    console.log("response", error.response.status);
                    console.log("message", error.response.data.message);
                    this.errorRes = error.response.data.message;
                    this.formLoader = 1
                })    
            }
        },
        async checkTodoTxt() {
            const todoTitle = document.getElementById('todoTitle').value;
            this.todoTitle = todoTitle.trim();
            // this.formTodoLoader = 1
            $('#todoBtn').prop('disabled', true);
            if(this.todoTitle.length > 0){
                $('#todoBtn').prop('disabled', false);
                // this.formTodoLoader = 2
            }

        },
        assignToHtml(users) {
            if(users){
                users = JSON.parse(JSON.stringify(users));
                var len = users.length;
                var htmls = '';
                for (var i = 0; i < len; i++) {
                        htmls += '<div class="mg-thumbnail">';
                            if(users[i].initials == null){
                                htmls += '<img src="'+this.img_url + '' + users[i].user_image+'" alt="'+users[i].name+'" title="'+users[i].name+'" />';
                            }else{
                                htmls += '<span title="'+users[i].name+'">'+users[i].initials+'</span>';
                            }
                        htmls += '</div>';      
                }
                return htmls; 
            }
        },
        labelsHtml(labels) {
            var htmls = '';
            if(labels){
                labels = JSON.parse(JSON.stringify(labels));
                var len = labels.length;
                for (var i = 0; i < len; i++) {
                    htmls += '<div class="badge" style="background-color: ' + labels[i].color_code + ' !important">';
                        htmls += '<span title="'+labels[i].label+'" class="labelSpanCls"  >'+labels[i].label+'</span>';
                        htmls += '</div>';
                    }
                }
            return htmls; 
        },
        async addCommentTasks() {
            this.formLoader = 2
            let _URL = ''

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            let _post = {}
            //const fd = new FormData;
            _URL = process.env.VUE_APP_API_URL + 'task-comment/create'
            // fd.append('description', this.v$.form.description.$model)
            // fd.append('task_id', $('.taskValId').val())
            // fd.append('attachments',  document.getElementById('fileAttachment').files[0])
            _post = {
                description: this.v$.form.description.$model,
                task_id: $('.taskValId').val(),
            }
            await axios.post(_URL, _post)
            .then(async (resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.$toast.success(resp.data.message);
                    // await this.getTaskTodo();
                    await this.getTaskComment();
                    await this.getTaskActivity();
                    this.form.description = '';
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    this.errorMessage = resp.data.message;
                }
                    this.formLoader = 1
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
            })     
        },
        async modalViewOpen() {
            $('.loadimg').show();
            this.startLoader()
            this.loadModal = 1
            this.teamOpen = 1
            $('.teamsBoxCls').hide()
            
            let task_id = $('.taskValId').val();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'task/get', {
                task_id: task_id
            })
            .then(async (resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    this.taskData = resp.data.data
                    this.form.description = '';
                    document.getElementById('previewTaskBtn').click();
                    $('.fltformmanagedm').addClass('d-none')
                    $('.dummytxtarayCls').removeClass('d-none')
                    this.teamUserData = resp.data.data.userids;
                    this.priorityData = resp.data.data.priority_id;
                    this.labelsData = resp.data.data.labelids;
                    this.$store.state.assignToVal = this.taskData.users
                    this.$store.state.labelsVal = resp.data.data.labels;
                    await this.getTaskTodo();
                    await this.getTaskComment();
                    await this.getTaskAttachment();
                    await this.getTaskActivity();
                    this.priorityOpen = 1
                    this.labelOpen = 1
                    this.loadModal = 2
                    $('.priorityBoxCls').hide()
                    $('.labelBoxCls').hide()
                    $('.loadimg').hide();
                    $(".descTksCls").removeClass('error')
                    $(".descTksCls").removeClass('errorCls')
                    $(".titTksCls").removeClass('error')
                    $(".titTksCls").removeClass('errorCls')
                    this.finishLoader()
                }
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.formLoader = 1
                // this.errorRes = error.response.data.message;
            })
        },
        async getTaskComment() {
            this.callChildTaskCommentMethod();
        },
        async getTaskAttachment() {
            this.callChildTaskAttachmentMethod(2);
        },
        async getTaskTodo() {
            this.callChildTaskTodoMethod();
        },
        async getTaskActivity() {
            this.callChildTaskActivityMethod(2);
        },
    },
    setup() {
        const childComponentTaskActivityRef = ref(null);
        const callChildTaskActivityMethod = (val) => {
            if (childComponentTaskActivityRef.value) {
                childComponentTaskActivityRef.value.getTaskActivity(val);
                // childComponentTaskActivityRef.value.checkActivity(val);
            }
        };
        const childComponentTaskCommentRef = ref(null);
        const callChildTaskCommentMethod = () => {
            if (childComponentTaskCommentRef.value) {
                childComponentTaskCommentRef.value.getTaskComment();
            }
        };
        const childComponentTaskAttachmentRef = ref(null);
        const callChildTaskAttachmentMethod = (test) => {
            if (childComponentTaskAttachmentRef.value) {
                childComponentTaskAttachmentRef.value.getTaskAttachment(test);
            }
        };
        const childComponentTaskTodoRef = ref(null);
        const callChildTaskTodoMethod = () => {
            if (childComponentTaskTodoRef.value) {
                childComponentTaskTodoRef.value.getTaskTodo(2);
            }
        };
        return {
            v$: useVuelidate(),
            callChildTaskActivityMethod,
            childComponentTaskCommentRef,
            callChildTaskCommentMethod,
            childComponentTaskActivityRef,
            callChildTaskAttachmentMethod,
            childComponentTaskAttachmentRef,
            callChildTaskTodoMethod,
            childComponentTaskTodoRef,
        }
    },
    validations() {
        return {
            form: {
                description: {
                    required,
                    min: minLength(2)
                },
            },
        }
    },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-tag.is-user {
    padding: 5px 8px;
    border-radius: 22px;
    background: #35495e;
    margin: 3px 3px 8px;
  }

  .multiselect-tag.is-user img {
    width: 18px;
    border-radius: 50%;
    height: 18px;
    margin-right: 8px;
    border: 2px solid #ffffffbf;
  }

  .multiselect-tag.is-user i:before {
    color: #ffffff;
    border-radius: 50%;;
  }

  .user-image {
    margin: 0 6px 0 0;
    border-radius: 50%;
    height: 22px;
  }
  .error{
    border: 2px solid red;
    font-size: 22px;
  }
</style>
