<template>
    <vs-button class="mg-btn-gold" color="primary" text-color="whiteprimary"  v-on:click="modalOpen">Add Project</vs-button>    
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#projectModals" id="addProjectBtn"> </button>  
    <div class="modal fade right" id="projectModals" tabindex="-1" style="display: none;" aria-hidden="true"  data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalType }} Project</h5> 
                    <button type="button" class="btn-close" id="closePop" data-bs-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <form @submit.prevent="onSubmit" class="projectFrm" id="projectFrm">
                    <div class="modal-body setformbackform">
                        <div class="row">
                            <div class="col-sm-12">
                                <span v-if="errorMessage != ''">
                                    <p class="alert alert-danger error-msg" >{{ errorMessage }}</p>
                                </span>
                            </div>
                        </div>                         
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="projectName" class="form-label">Name<span class="requiredCls">*</span></label> 
                                    <input type="text" class="form-control" name="projectName" id="projectName" placeholder="Enter project name"  v-model="v$.form.projectName.$model"> 
                                    <div class="input-errors" v-for="(error, index) of v$.form.projectName.$errors" :key="index">
                                        <div class="error-msg">{{ error.$message }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="formmginput-controlx mb-2"> 
                                        <label for="startDate" class="form-label">Start Date</label> 
                                        <appDatePicker 
                                            :value="currentDate" 
                                            :name="'startDate'" 
                                            :id="'startDate'" 
                                            :class="'form-control p-0'" 
                                            :format="'dd-MM-yyyy'" 
                                            v-model="v$.form.startDate.$model"
                                            :placeholder="'Start Date'"
                                            >
                                        </appDatePicker>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                 <div class="formmginput-controlx mb-2"> 
                                    <label for="endDate" class="form-label">Deadline</label> 
                                    <appDatePicker 
                                        :value="currentDate" 
                                        :name="'endDate'" 
                                        :id="'endDate'" 
                                        :class="'form-control p-0'" 
                                        :format="'dd-MM-yyyy'" 
                                        :placeholder="'End Date'"
                                        v-model="v$.form.endDate.$model"
                                        >
                                    </appDatePicker>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="priorityDropId" class="form-label">Priority</label>
                                    <Select2 v-model="v$.form.priority_id.$model" v-bind:id="'priorityDropId'" :options="$store.state.priority.PriorityList" :placeholder="'Select Priority'" :settings="{ dropdownParent: '#projectModals' }" />
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="formmginput-controlx mb-2">            
                                    <label for="teamDropIds" class="form-label">Teams<span class="requiredCls">*</span></label>
                                    <Multiselect v-bind:name="'teamId[]'" v-bind:id="'teamFilterId'" v-model="v$.form.team_ids.$model"
                                        mode="multiple" placeholder="Select Team" track-by="name" label="name" :close-on-select="false" :search="true"
                                        :hide-selected="false"
                                        :options="$store.state.teamMember.TeamMemberImgFilterList">
                                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                        <div
                                            class="multiselect-tag is-user"
                                            :class="{
                                                'is-disabled': disabled
                                            }"
                                        >
                                            <div v-if="option.initials == null">  
                                                <img :src="img_url + '' + option.user_image">
                                                {{ option.label }}
                                            </div>
                                            <div v-else>  
                                                {{ option.initials }} {{ option.label }}
                                            </div>
                                            <span
                                            v-if="!disabled"
                                            class="multiselect-tag-remove"
                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                            >
                                            <span class="multiselect-tag-remove-icon"></span>
                                            </span>
                                        </div>
                                        </template>
                                        <template v-slot:option="{ option }">
                                    <!-- <div v-html="readMore(element.title, element.task_id)" />  -->
                                    <div v-if="option.initials == null">  
                                        <img class="user-image" :src="img_url + '' + option.user_image" >  {{ option.label }}
                                    </div>
                                    <div v-else>  
                                         {{ option.initials }} {{ option.label }}
                                    </div>
                                        </template>
                                    </Multiselect>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="formmginput-controlx mb-2"> 
                                    <label for="projectDesc" class="form-label">Description<span class="requiredCls">*</span></label> 
                                    <textarea class="form-control" placeholder="Enter project description" name="projectDesc" id="projectDesc"></textarea>
                                </div>  
                            </div>
                        </div>                        
                    </div>
                    <div class="modal-footer">                           
                        <button type="button" class="mg-btn-gold" id="saveUpdateBtn" :disabled="v$.form.$invalid" v-on:click="addProject" v-if="formLoader === 1">{{ buttonName }}</button>
                        <button type="button" class="mg-btn-gold" id="saveUpdateBtn" :disabled="v$.form.$invalid" v-else><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import $ from "jquery";
import useVuelidate from '@vuelidate/core'
import {
    required
} from '@vuelidate/validators'
import appDatePicker from 'vuejs3-datepicker';
import Select2 from 'vue3-select2-component';
import Multiselect from '@vueform/multiselect'
export default {
    name: 'AddEditProjectPage',
    components: {
        appDatePicker,
        Select2,
        Multiselect,
    },
    data() {
        return {
            img_url: process.env.VUE_APP_IMG_URL,
            myValueTeams: [],
            modalType: 'Add',
            buttonName: 'Save',
            errorMessage: '',
            formLoader: 1,
            logoFile: null,
            currentDate: '',
            form: {
                projectName: '',
                projectDesc: '',
                startDate: '',
                endDate: '',
                priority_id: '',
                team_ids: [],
                
            },
        }
    },
    methods: {
        async modalOpen() {
            this.errorMessage = ''
            this.form.projectName = '';
            this.form.startDate = '';
            this.form.endDate = '';
            this.form.priority_id = '';
            this.v$.form.team_ids.$model = [];
            this.modalType = 'Add';
            this.buttonName = 'Save',
            this.v$.$reset()
            document.getElementById('projectDesc').value = '';
            document.getElementById('addProjectBtn').click();
            setTimeout(async () =>
                $("#projectName").focus(),
                500);
        },
        async modalEditOpen() {
            let project_id = $('.projectValId').val();
            $('.loadimg').show();
            this.errorMessage = ''
            this.form.projectName = '';
            this.form.startDate = '';
            this.form.endDate = '';
            this.form.priority_id = '';
            document.getElementById('projectDesc').value = '';
            this.v$.form.team_ids.$model = [];
            this.modalType = 'Edit',
            this.buttonName = 'Update',
            this.v$.$reset()
            this.formLoader = 2
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;
            await axios.post(process.env.VUE_APP_API_URL + 'project/get', {
                project_id: project_id
            })
            .then((resp) => {
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('projectName').value = resp.data.data.title;
                    document.getElementById('projectDesc').value = resp.data.data.description;

                    this.v$.form.startDate.$model = resp.data.data.start_date;
                    this.v$.form.endDate.$model = resp.data.data.end_date;
                    this.v$.form.projectName.$model = resp.data.data.title;
                    this.v$.form.priority_id.$model = resp.data.data.priority_id;
                    this.v$.form.team_ids.$model = resp.data.data.userids;

                    document.getElementById('addProjectBtn').click();
                    this.v$.$reset()
                    $('.loadimg').hide();
                    setTimeout(async () =>
                        $("#projectName").focus(),
                    500);
                }
                this.formLoader = 1
            })
            .catch(error => {
                // this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.formLoader = 1
                // this.errorRes = error.response.data.message;
            })
        },
        async addProject() {
            this.formLoader = 2
            let _URL = ''
            let _post = {}
            var projectDesc = $('#projectDesc').val();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.state.user.accessToken;

            if (this.modalType == 'Edit') {
                _URL = process.env.VUE_APP_API_URL + 'project/update'
                let project_id = $('.projectValId').val();
                _post = {
                    project_id: project_id,
                    title: this.v$.form.projectName.$model,
                    description: projectDesc,
                    start_date: this.v$.form.startDate.$model,
                    end_date: this.v$.form.endDate.$model,
                    priority_id: this.v$.form.priority_id.$model,
                    user_ids: this.v$.form.team_ids.$model,
                }
            } else {
                _URL = process.env.VUE_APP_API_URL + 'project/create'
                _post = {
                    title: this.v$.form.projectName.$model,
                    description: projectDesc,
                    start_date: this.v$.form.startDate.$model,
                    end_date: this.v$.form.endDate.$model,
                    priority_id: this.v$.form.priority_id.$model,
                    user_ids: this.v$.form.team_ids.$model,
                }
            }
            await axios.post(_URL, _post)
                .then((resp) => {
                this.$store.state.project.loadProjectData = 1
                if (resp.status == 200 && resp.data.status == 'success') {
                    document.getElementById('closePop').click();
                    this.$toast.success(resp.data.message);
                    this.$emit('reload-table-data');
                } else if (resp.status == 200 && resp.data.status == 'failure') {
                    // this.errorMessage = resp.data.message;
                    this.errorMessage = resp.data.message;
                    // if (resp.data.data.details[0]) {
                    //     this.errorMessage = resp.data.data.details[0].message;
                    // } else {
                    //     this.errorMessage = resp.data.message;
                    // }
                }
                    this.formLoader = 1
            })
            .catch(error => {
                this.errorMessage = error.message;
                console.error("There was an error!", error);
                console.log("response", error.response.status);
                console.log("message", error.response.data.message);
                this.errorRes = error.response.data.message;
                this.formLoader = 1
            })  
        },
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    validations() {
        return {
            form: {
                projectName: {
                    required
                },
                startDate: {
                    // required
                },
                endDate: {
                    // required
                },
                priority_id: {
                    // required
                },
                team_ids: {
                    required
                },
                
            },
        }
    },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-tag.is-user {
    padding: 5px 8px;
    border-radius: 22px;
    background: #35495e;
    margin: 3px 3px 8px;
  }

  .multiselect-tag.is-user img {
    width: 18px;
    border-radius: 50%;
    height: 18px;
    margin-right: 8px;
    border: 2px solid #ffffffbf;
  }

  .multiselect-tag.is-user i:before {
    color: #ffffff;
    border-radius: 50%;;
  }

  .user-image {
    margin: 0 6px 0 0;
    border-radius: 50%;
    height: 22px;
  }
</style>