<template>      
    <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
        <!-- Sidebar - Brand -->
        <a class="sidebar-brand d-flex align-items-center justify-content-center" href="javascript:;">
            <img src="../../assets/images/logo.svg" alt="">
        </a>
        <!-- Nav Item - Dashboard -->
        <li class="nav-item">
            <router-link to="/" class="nav-link collapsed" exact>
                <DashboardSvgIcon />
                <span>Dashboard</span> 
            </router-link>
        </li>
        <!-- Nav Item - Tables -->
        <li class="nav-item">
            <router-link to="/tasks" class="nav-link collapsed taskMenuCls" exact>
                <TaskSvgIcon />
                <span>Tasks</span> 
            </router-link>             
        </li>
        <li class="nav-item" >
            <router-link to="/projects" class="nav-link collapsed projectMenuCls" exact>
                <ProjectSvgIcon />
                <span>Projects</span> 
            </router-link>             
        </li>
        <li class="nav-item" v-if="$store.state.user.userInfo.role_name === 'Admin'">
            <router-link to="/team-member" class="nav-link collapsed" exact>
                <TeamSvgIcon />
                <span>Team Member</span> 
            </router-link>             
        </li>
        <li class="nav-item">
            <router-link to="/notifications" class="nav-link collapsed" exact>
                <NotificationSvgIcon />
                <span>Notifications</span> 
                <span class="bg_tsk_ani dots"></span>
            </router-link>             
        </li>
        <li class="nav-item d-none" v-if="$store.state.user.userInfo.role_name === 'Admin'">
            <router-link to="/settings" class="nav-link collapsed" exact>
                <SettingSvgIcon />
                <span>Setting</span> 
            </router-link>             
        </li>
        <li class="nav-item">
            <a class="nav-link collapsed" href="javascript:;" exact>
                <LogOutSvgIcon />
                <span data-bs-toggle="modal" data-bs-target="#logoutModals">Logout</span> 
            </a>
        </li>


        <div class="set_footer_sidebar mt-4">
            <div class="set_footer_sidebar_inner">
                <span>
                    <img v-bind:src="img_url+''+$store.state.user.userInfo.user_image">
                </span>
                <p>
                    <i>{{ $store.state.user.userInfo.name }}</i>
                    <b>{{ $store.state.user.userInfo.role_name }}</b>
                </p>
            </div>
        </div>
        <!-- Sidebar Toggler (Sidebar) -->
        <!-- <div class="text-center d-none d-md-inline">
            <button class="rounded-circle border-0" id="sidebarToggle"><i class="bi bi-caret-left"></i></button>
        </div> -->


    </ul>  
</template>
<script>
import TaskSvgIcon from '../../components/Widgets/Icon/TaskSvgIcon.vue'
import DashboardSvgIcon from '../../components/Widgets/Icon/DashboardSvgIcon.vue'
import ProjectSvgIcon from '../../components/Widgets/Icon/ProjectSvgIcon.vue'
import TeamSvgIcon from '../../components/Widgets/Icon/TeamSvgIcon.vue'
import NotificationSvgIcon from '../../components/Widgets/Icon/NotificationSvgIcon.vue'
import SettingSvgIcon from '../../components/Widgets/Icon/SettingSvgIcon.vue'
import LogOutSvgIcon from '../../components/Widgets/Icon/LogOutSvgIcon.vue'
export default {
    name: 'SideMenuPage',
    components: {
        TaskSvgIcon,
        DashboardSvgIcon,
        ProjectSvgIcon,
        NotificationSvgIcon,
        TeamSvgIcon,
        SettingSvgIcon,
        LogOutSvgIcon,
    },
    data() {
      return {
            img_url: process.env.VUE_APP_IMG_URL,
        }
    },
}
</script>